<template>
<div class="s-layout" :style="colorObject">
  <div class="s-container">
    <Header
      :meta="meta"
      :page-title="getModuleConfig('page_title')"
      :page-image="getModuleConfig('page_image')"
      :page-kv-image="getModuleConfig('page_kv_image')"
      :header-section="getModuleConfig('header_section')"
    />

    <Loading
      v-if="isLoading"
      loading-message="載入中..."
      :background-color="'#ffffff'"
    />

    <div class="page">
      <div class="p-3 liff-reservation-form">
        <template v-if="hasDefaultPage && currentPageNumber === 0">
          <SubjectLayout v-if="false === hasSetUpFieldMappingForStartAt" :subject="subjectForStartAt" style="margin-bottom: 24px">
            <DatePicker v-model="start_at" :to-first-second="true" />
          </SubjectLayout>

          <SubjectLayout v-if="false === hasSetUpFieldMappingForEndAt" :subject="subjectForEndAt" style="margin-bottom: 24px">
            <DatePicker v-model="end_at" :to-last-second="true" />
          </SubjectLayout>
        </template>

        <DynamicForm
          ref="dynamicForm"
          v-if="false == isLoading"
          :input-subjects="subjectsOfCurrentPageWithoutSection"
          v-model="dynamicFormInput"
          :key="currentPageNumber"
          :validation-filter="onlySubjectsInCurrentPage"
        >
        </DynamicForm>

        <b-card
          header-tag="header"
          class="mt-4 liff-reservation-form__card"
          v-for="(section, i) in sectionsOfCurrentPage"
          :key="`section${i}page${currentPageNumber}`"
        >
          <template #header>
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0">{{ section.title }}</h6>
            </div>
          </template>

          <DynamicForm
            ref="dynamicFormForSection"
            :input-subjects="subjectsOfCurrentSection(section)"
            v-model="dynamicFormInput"
            :key="currentPageNumber"
          ></DynamicForm>
        </b-card>

        <div class="step-indicator my-4" v-if="visiblePages.length > 1">
          <div
            v-for="index in visiblePages.length"
            :key="index"
            class="step-indicator__step"
            :class="{ 'step-indicator__step--filled': index <= currentPageNumber + 1, 'step-indicator__step--current': index === currentPageNumber + 1 }"
          ></div>
        </div>

        <div class="d-flex s-space-x-2 mt-4">
          <SharedButton class="s-btn-outline-primary" v-if="currentPageNumber !== 0" @click="currentPageNumber--">
            上一步
          </SharedButton>
          <SharedButton class="s-btn-bg-primary" v-if="currentPageNumber + 1 !== visiblePages.length" @click="proceedToNextStep">
            下一步
          </SharedButton>
          <SharedButton class="s-btn-bg-primary" v-if="currentPageNumber + 1 === visiblePages.length" @click="submit">
            送出
          </SharedButton>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import bookingApi from '@/apis/liff/v2/booking'
import bookingPresetApi from '@/apis/liff/v2/booking-preset'
import DatePicker from "@/components/AppDatePicker.vue";
import DynamicForm from "@/components/DynamicForm/DynamicForm.vue";
import SubjectLayout from "@/components/DynamicForm/SubjectLayout.vue";
import SharedButton from '@/components/Page/Liff/Shared/Button.vue';
import Header from '@/components/Page/Liff/Shared/HeaderV3'
import Loading from "@/components/Page/Liff/Shared/Loading";
import { themeColorFn } from "@/mixins/liff/themeColor";
import isEmpty from "@/utils/isEmpty";
import { generateModuleConfigGetter } from "@/utils/liff/vueStoreHelpers";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [
    themeColorFn({ themeConfigPage: 'booking' })
  ],
  components: {
    DatePicker,
    DynamicForm,
    SubjectLayout,
    SharedButton,
    Header,
    Loading,
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
    bookingPresetId() {
      return this.$route.params.bookingPresetId
    },
    sections() {
      return _.get(this.bookingPreset, 'config.sections', []);
    },
    pages() {
      return _.get(this.bookingPreset, 'config.pages', []);
    },
    hasDefaultPage() {
      const visible = subjectOrSection => isEmpty(subjectOrSection.page_id) && this.passesVisibleCondition(subjectOrSection);
      return this.subjects.filter(visible).filter(subject => false === _.get(subject, 'dashboard_only', false)).length > 0
        || this.sections.filter(visible).length > 0
        || false === this.hasSetUpFieldMappingForStartAt
        || false === this.hasSetUpFieldMappingForEndAt;
    },
    visiblePages() {
      const pagesFromConfig = _.sortBy(this.pages.filter(this.passesVisibleCondition), 'order');
      return this.hasDefaultPage ? [{ _id: null, title: null, order: 0 }, ...pagesFromConfig] : pagesFromConfig;
    },
    subjectsOfCurrentPageWithoutSection() {
      return this.subjects.filter(subject => {
        return isEmpty(subject.section_id)
          && (isEmpty(this.pageIdOfCurrentPage) ? isEmpty(subject.page_id) : subject.page_id === this.pageIdOfCurrentPage)
          && this.passesVisibleCondition(subject)
          && false === _.get(subject, 'dashboard_only', false)
      });
    },
    sectionsOfCurrentPage() {
      return this.sections.filter(section => {
        return (isEmpty(this.pageIdOfCurrentPage) ? isEmpty(section.page_id) : section.page_id === this.pageIdOfCurrentPage)
          && this.passesVisibleCondition(section)
      });
    },
    pageIdOfCurrentPage() {
      return _.get(this.visiblePages[this.currentPageNumber], '_id', null);
    },
    hasSetUpFieldMappingForStartAt() {
      return _.get(this.bookingPreset, 'config.booking_mapping.bookings.start_at', null) !== null;
    },
    hasSetUpFieldMappingForEndAt() {
      return _.get(this.bookingPreset, 'config.booking_mapping.bookings.end_at', null) !== null;
    },
  },
  data() {
    return {
      bookingPreset: {},
      start_at: null,
      end_at: null,
      dynamicFormInput: {},
      isLoading: true,
      subjects: [],
      subjectForStartAt: {
        id: uuidv4(),
        title: '開始時間',
        required: true,
        memo: null,
      },
      subjectForEndAt: {
        id: uuidv4(),
        title: '結束時間',
        required: true,
        memo: null,
      },
      currentPageNumber: 0,
    }
  },
  async mounted() {
    this.isLoading = true
    await this.fetchFields()
    this.isLoading = false
  },
  methods: {
    ...mapActions('liffBooking', ['setSubmittedBooking', 'setViewingBookingPreset']),
    getModuleConfig: generateModuleConfigGetter('liff_booking'),
    passesVisibleCondition(subjectOrSectionOrPage) {
      const anyConditions = _.get(subjectOrSectionOrPage, 'visible_when_any', []);
      if (anyConditions.length === 0) {
        return true;
      }
      return anyConditions.some(condition => this.subjects.some(possibleSubject => {
        return condition.field_id === possibleSubject.id
          && condition.value === this.dynamicFormInput[possibleSubject.id];
      }));
    },
    subjectsOfCurrentSection(section) {
      return this.subjects.filter(subject => {
        return subject.section_id === section._id
          && this.passesVisibleCondition(subject)
          && false === _.get(subject, 'dashboard_only', false)
      });
    },
    onlySubjectsInCurrentPage(subject) {
      return this.subjectsOfCurrentPageWithoutSection.some(sub => sub._id === subject._id) ||
        this.sectionsOfCurrentPage.some(section => this.subjectsOfCurrentSection(section).some(sub => sub.id === subject._id))
    },
    async fetchFields() {
      const res = await bookingPresetApi.get(this.bookingPresetId)
      this.bookingPreset = res
      // 如果是精靈模式要轉導去Reservation
      if (this.bookingPreset.type == 'wizard') {
        this.$router.push({
          name: "LiffReservation",
        })
      }
      this.subjects = res.config.fields.map(field => {
        const id = _.get(field, 'config._id') || _.get(field, '_id')
        const { config, ...fieldColumns } = field
        return { id, ...config, ...fieldColumns }
      })
    },
    async validateSubjectsOfCurrentPage() {
      const validationForSubjectsWithoutSection = await this.$refs.dynamicForm.validate()

      let validationForSubjectsInSections = []
      if (this.$refs.dynamicFormForSection) {
        validationForSubjectsInSections = await Promise.all(this.$refs.dynamicFormForSection.map(formForSection => formForSection.validate()))
      }

      return true === validationForSubjectsWithoutSection && validationForSubjectsInSections.every(Boolean)
    },
    async proceedToNextStep() {
      if (false === await this.validateSubjectsOfCurrentPage()) {
        return
      }
      this.currentPageNumber++
    },
    async submit() {
      if (false === await this.validateSubjectsOfCurrentPage()) {
        return
      }

      this.isLoading = true
      try {
        const dataToSave = {
          preset_id: this.bookingPresetId,
          fields: this.dynamicFormInput,
        }

        if (false === this.hasSetUpFieldMappingForStartAt) {
          dataToSave.start_at = this.start_at
        }

        if (false === this.hasSetUpFieldMappingForEndAt) {
          dataToSave.end_at = this.end_at
        }

        const res = await bookingApi.saveBooking(dataToSave)
        this.setSubmittedBooking(res.data)
        this.setViewingBookingPreset(this.bookingPreset)
        this.$router.push({ name: "LiffBookingFinished", params: { bookingPresetId: this.bookingPresetId } })
      } catch (e) {
        console.error(e)
        this.$swal.fire({ title: "送出失敗", type: "error" })
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
  --s-danger: #fe0000;
}

.s-container {
  min-height: 100vh;
}

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);

  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
    color: var(--liff-primary_text_color) !important;
  }

  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color) !important;
    margin-bottom: 16px;
    text-align: center;

    b {
      color: var(--primary-color) !important;
      font-weight: 600;
    }
  }

  &__button {
    padding: 12px;
    color: var(--liff-primary_text_color) !important;
  }
}

.liff-reservation-form {
  &__card {
    border-radius: 10px;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    .card-header {
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid #E5E5EA;
      font-size: 16px;
      font-weight: 500;
      padding: 16px 16px 12px 16px;
    }
  }
  &__textarea {
    border-radius: 10px;
    border: 1px solid #636366;
    color: #2C2C2E;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    padding: 16px 12px;
  }

  input {
    font-size: 14px;
    height: auto;
    line-height: 1.2;
    border-bottom: 1px solid #E5E5EA;
    padding-bottom: 6px;
    }
  }

.step-indicator {
  display: flex;
  justify-content: center;
  > * + * {
    margin-left: 12px;
  }
  &__step {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid var(--liff-button-color);
    &--filled {
      background-color: var(--liff-button-color);
    }
    &--current {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 1px solid var(--liff-button-color);
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
